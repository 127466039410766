import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "./../context/socket";
import CreateMatchButton from "./../components/Buttons/CreateMatchButton";
import MatchMakeButton from "./../components/Buttons/MatchMakeButton";
import BlueButton from "./../components/Buttons/TEMPLATES/BlueButton";
import GreyButton from "../components/Buttons/TEMPLATES/GreyButton";
import ThreeDimView from "../components/ThreeDimView";
import CubeSpacingSlider from "../components/Sliders/CubeSpacingSlider";
import MatchMakingModal from "../components/Modals/Modal_MatchMaking";

const Home = () => {
  const [queuing, setQueuing] = React.useState(false);
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  function generateRandomMatchID(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let matchID = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      matchID += characters.charAt(randomIndex);
    }
    return matchID;
  }
  function createMatch() {
    const matchID = generateRandomMatchID(20);
    socket.emit("create_match", matchID);
    navigate(`/${matchID}`, { state: { host: true } });
  }
  function queueMatch() {
    socket.emit("queue_match");
    setQueuing(true);
  }
  function leaveQueue() {
    socket.emit("leave_queue");
    setQueuing(false);
  }
  useEffect(() => {
    socket.on("match_found", (matchID, isHost) => {
      navigate(`/${matchID}`, { state: { host: isHost } });
    });
  }, []);

  return (
    <>
      {queuing && <MatchMakingModal leaveQueue={leaveQueue} />}
      <div className="flex">
        <div className="flex flex-col space-y-10 m-10 w-1/2">
          <MatchMakeButton text="Find an Opponent" onMouseUp={() => queueMatch()} onClick={() => {}}></MatchMakeButton>
          <CreateMatchButton text="Create A Private Match" onMouseUp={() => createMatch()} onClick={() => {}}></CreateMatchButton>
          <BlueButton text="Tutorial" onMouseUp={() => {}} onClick={() => {}}></BlueButton>
          <GreyButton text="Setings" onMouseUp={() => {}} onClick={() => {}}></GreyButton>
          <CubeSpacingSlider setCubeSpacing={() => {}} />
        </div>
        <div className="w-1/2">
          <ThreeDimView
            autoRotate={true}
            enablePan={false}
            enableRotate={false}
            enableZoom={false}
            disableInteract={true}
            updateCell={() => {}}
          ></ThreeDimView>
        </div>
      </div>
    </>
  );
};

export default Home;
