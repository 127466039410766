import React from "react";
import eye from "./../../images/eye.svg";

const ViewButton = (props) => {
  //const boxShadowColour = ["#374151", "#d1d5db"];
  return (
    <div
      onClick={() => props.onClick()}
      onMouseUp={() => setTimeout(() => props.onMouseUp(), 100)}
      className="flex justify-center items-center space-x-5 p-5 button w-full h-16 bg-gray-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#374151,0_0px_0_0_#d1d5db]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#374151,0_15px_0_0_#d1d5db]
    border-b-[1px] border-gray-400"
    >
      <img className="w-16 h-16" src={eye}></img>
      <span className="flex flex-col justify-center items-center h-full text-white font-bold text-3xl ">{props.text}</span>
    </div>
  );
};

export default ViewButton;
