import React from "react";

const GreyButton = (props) => {
  //const boxShadowColour = ["#374151", "#d1d5db"];
  return (
    <div
      onClick={() => props.onClick()}
      onMouseUp={() => setTimeout(() => props.onMouseUp(), 100)}
      className="button w-40 h-16 bg-gray-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#374151,0_0px_0_0_#1f2937]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#374151,0_15px_0_0_#1f2937]
    border-b-[1px] border-gray-400"
    >
      <span className="flex flex-col justify-center items-center h-full text-white font-bold text-lg ">{props.text}</span>
    </div>
  );
};

export default GreyButton;
