import React from "react";
import flag from "./../../images/flag.svg";
const GreenButton = (props) => {
  //const boxShadowColour = ["#15803d", "#86efac"];
  return (
    <div
      onMouseUp={() => setTimeout(() => props.onMouseUp(), 100)}
      onClick={() => props.onClick()}
      className="flex justify-center items-center space-x-5 p-5 button w-full h-32 bg-green-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#15803d,0_0px_0_0_#86efac]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#15803d,0_15px_0_0_#86efac]
    border-b-[1px] border-green-400"
    >
      <img className="w-min h-full" src={flag}></img>
      <span className="flex flex-col justify-center items-center h-full text-white font-bold text-5xl ">{props.text}</span>
    </div>
  );
};

export default GreenButton;
