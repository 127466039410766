import React from "react";
import clock from "./../images/clock.svg";
const SideCard = (props) => {
  const totalSeconds = Math.floor(props.timer);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = (totalSeconds % 60).toString().padStart(2, "0");
  const milliseconds = Math.floor((props.timer - totalSeconds) * 100)
    .toString()
    .padStart(2, "0");
  let timeString = "";
  if ((totalSeconds <= 0 && milliseconds <= 0) || totalSeconds < 0) {
    timeString = "TIME'S UP";
  } else if (totalSeconds < 30) {
    timeString = `${seconds}:${milliseconds}`;
  } else if (totalSeconds < 60) {
    timeString = `${seconds}`;
  } else {
    timeString = `${minutes}:${seconds}`;
  }

  return (
    <div className={`bg-${props.side === "left" ? "blue" : "red"}-300 w-full h-20 rounded-lg flex flex-col items-center justify-center`}>
      <p className="text-2xl">
        {(props.side === "left" && props.player === 1) || (props.side === "right" && props.player === -1) ? "YOU" : "OPP"}
      </p>
      <div>
        <img className="" src={clock} />
        <p>{timeString}</p>
      </div>
    </div>
  );
};

export default SideCard;
