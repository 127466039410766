import React from "react";
import rotate from "./../images/rotate.svg";
import crown from "./../images/crown.svg";
const Cell = (props) => {
  const showCoords = false;
  const coordinates = `${props.sliceIndex}-${props.rowIndex}-${props.cellIndex}`;
  let winningCell = false;
  if (props.winningMatch) {
    for (let i = 0; i < props.winningMatch.length; i++) {
      if (
        props.winningMatch[i][0] === props.sliceIndex &&
        props.winningMatch[i][1] === props.rowIndex &&
        props.winningMatch[i][2] === props.cellIndex
      ) {
        winningCell = true;
      }
    }
  }

  //deciding the colour of the border
  let borderColour = "";
  let lastMove =
    `${props.sliceIndex}-${props.rowIndex}-${props.cellIndex}` === `${props.lastMove[0]}-${props.lastMove[1]}-${props.lastMove[2]}`;
  //check if this coordinate is being hovered in 3dview
  let cubeHover = false;
  if (!props.passiveHover) {
  } else if (
    `${props.sliceIndex}-${props.rowIndex}-${props.cellIndex}` ===
    `${props.passiveHover[0]}-${props.passiveHover[1]}-${props.passiveHover[2]}`
  ) {
    cubeHover = true;
  }
  //check if this coordinate has been right clicked in 2dview
  let cubeHighlight = false;
  if (!props.cubeHighlight) {
  } else if (
    `${props.sliceIndex}-${props.rowIndex}-${props.cellIndex}` ===
    `${props.cubeHighlight[0]}-${props.cubeHighlight[1]}-${props.cubeHighlight[2]}`
  ) {
    cubeHighlight = true;
  }

  if (cubeHighlight) {
    borderColour = "border-2 border-yellow-500";
  } else if (cubeHover) {
    borderColour = "border-2 border-white";
  } else if (lastMove) {
    borderColour = "border-2 border-gray-700";
  }

  //deciding the colour and image of the cell
  const firstMove = props.turnNumber === 1 && props.cell;
  let cellColour = "";
  if (props.cell === 0 && props.turn === props.player) {
    cellColour = "bg-neutral-500 cursor-pointer";
  } else if (props.cell === 0 && props.turn !== props.player) {
    cellColour = "bg-black";
  } else if (props.cell === 1) {
    cellColour = "bg-blue-500";
  } else if (props.cell === -1) {
    cellColour = "bg-red-500";
  }
  if (firstMove && props.turn === props.player) {
    cellColour = cellColour.concat(" cursor-pointer");
  }

  return (
    <div
      className={`w-4 h-4 text-center text-white font-bold rounded-md flex flex-col items-center justify-center ${cellColour} ${borderColour}`}
      onClick={() => props.updateCell(props.cell, props.sliceIndex, props.rowIndex, props.cellIndex)}
      onContextMenu={(e) => {
        e.preventDefault(); // Prevent the default context menu
        if (
          props.cubeHighlight[0] === props.sliceIndex &&
          props.cubeHighlight[1] === props.rowIndex &&
          props.cubeHighlight[2] === props.cellIndex
        ) {
          props.setCubeHighlight([null, null, null]);
        } else {
          props.setCubeHighlight([props.sliceIndex, props.rowIndex, props.cellIndex]);
        }
        // console.log("right click");
        // Handle the right-click event here
        // You can add your logic for the right-click action
      }}
      onPointerOver={() => props.setPassiveHover([props.sliceIndex, props.rowIndex, props.cellIndex])}
    >
      {showCoords ? coordinates : ""}
      {firstMove ? <img className="w-10 h-10" src={rotate}></img> : ""}
      {winningCell ? <img className="w-to h-10" src={crown}></img> : ""}
    </div>
  );
};

export default Cell;
