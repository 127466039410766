import React from "react";
import ViewButton from "../Buttons/ViewButton.jsx";
import RematchButton from "../Buttons/RematchButton.jsx";
const ResetGameModal = (props) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-max bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">{props.victor === 1 ? "Blue" : "Red"} Wins</h3>
              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                {props.victor === 1 ? "Red" : "Blue"} Will Start
                {/* {props.winningVector} */}
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b space-x-5">
              <ViewButton text="View" onClick={() => {}} onMouseUp={() => props.setViewEnd(true)}></ViewButton>
              {/* <ViewButton text="New Game" onClick={() => props.requestNewGame()}></ViewButton> */}
              <RematchButton
                text="Rematch"
                onMouseUp={() => props.requestRematch()}
                onClick={() => {}}
                rematchStatus={props.rematchStatus}
              ></RematchButton>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ResetGameModal;
