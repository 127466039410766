import React, { useEffect } from "react";
import "./Background.css";

const Background = (props) => {
  useEffect(() => {
    const section = document.querySelector(".sec");

    function changeBackground(side, colour) {
      // Create a new div
      const newDiv = document.createElement("div");
      newDiv.style.background = colour;
      newDiv.classList.add(`clipPath-circle-${side}`);
      setTimeout(() => {
        newDiv.classList.add("active");
      }, 1);

      // Set the background color of the section

      // Insert the new div before the button
      section.insertBefore(newDiv, section.querySelector(".end"));

      // Remove the new div after a delay
      setTimeout(() => {
        section.style.background = colour;
        newDiv.remove();
      }, 1200);
    }
    const side = props.turn === 1 ? "left" : "right";
    const colour =
      props.turn === 1
        ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)"
        : "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(121,9,9,1) 65%, rgba(36,0,0,1) 100%)";
    changeBackground(side, colour);
  }, [props.turn]);

  return (
    <section className="sec">
      <div className="end"></div>
    </section>
  );
};

export default Background;
