import React from "react";
import handshakeAllGray from "./../../images/handshake-all-gray.svg";
import handshakeAllGreen from "./../../images/handshake-all-green.svg";
import handshakeLeftGreen from "./../../images/handshake-left-green.svg";
import handshakeRightGreen from "./../../images/handshake-right-green.svg";
const RematchButton = (props) => {
  //const boxShadowColour = ["#374151", "#d1d5db"];
  let rematchIcon = handshakeAllGray;
  const rematchStatus = props.rematchStatus;
  if (rematchStatus[0] === 1 && rematchStatus[1] === 1) {
    rematchIcon = handshakeAllGreen;
  } else if (rematchStatus[0] === 1 && rematchStatus[1] === 0) {
    rematchIcon = handshakeLeftGreen;
  } else if (rematchStatus[0] === 0 && rematchStatus[1] === 1) {
    rematchIcon = handshakeRightGreen;
  }
  return (
    <div
      onMouseUp={() => setTimeout(() => props.onMouseUp(), 100)}
      onClick={() => props.onClick()}
      className="flex justify-center items-center space-x-5 p-5 button w-full h-16 bg-yellow-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#a16207,0_0px_0_0_#fde047]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#a16207,0_15px_0_0_#fde047]
    border-b-[1px] border-yellow-400"
    >
      <img className="w-16 h-16" src={rematchIcon}></img>
      {props.text.length > 0 ? (
        <span className="flex flex-col justify-center items-center h-full text-white font-bold text-3xl ">{props.text}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default RematchButton;
