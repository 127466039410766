import React from "react";
import copy from "./../../images/copyicon.svg";
import checkmark from "./../../images/checkmark.svg";
import thincheckmark from "./../../images/thincheckmark.svg";
const InvitationButton = (props) => {
  //const boxShadowColour = ["#15803d", "#86efac"];
  let icon = copy;
  return (
    <div
      onClick={() => props.onClick()}
      onMouseUp={() =>
        setTimeout(() => {
          props.onMouseUp();
          icon = thincheckmark;
        }, 100)
      }
      className="flex justify-center items-center space-x-5 p-5 button w-full h-16 bg-green-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#15803d,0_0px_0_0_#86efac]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#15803d,0_15px_0_0_#86efac]
    border-b-[1px] border-green-400"
    >
      <span className="flex flex-col justify-center items-center h-full text-white font-bold text-3xl ">{props.text}</span>

      <img className="w-16 h-10" src={icon}></img>
    </div>
  );
};

export default InvitationButton;
