import React from "react";
import { Canvas } from "@react-three/fiber";
import Cube from "./Cube";
import CustomOrbitControls from "./CustomOrbitControls";

const ThreeDimView = (props) => {
  const cubeArray = props.threeDimArray
    ? props.threeDimArray
    : new Array(4)
        .fill(null)
        .map(() => new Array(4).fill(null).map(() => new Array(4).fill(0)))
        .slice(0, 4);
  const cubeSpacing = props.cubeSpacing ? props.cubeSpacing : 0.1;
  const cubeLength = 1;

  return (
    <Canvas camera={{ position: [10, 5, 10] }} flat>
      {/*idk what flat and linear does */}
      <ambientLight />
      <pointLight position={[20, 20, 20]} />
      {cubeArray.map((slice, sliceIndex) => {
        return slice.map((row, rowIndex) => {
          return row.map((cell, cellIndex) => {
            return (
              <Cube
                key={cellIndex}
                position={[
                  sliceIndex - (cubeSpacing * 1.5 + cubeLength * 2 - cubeLength / 2) + sliceIndex * cubeSpacing,
                  rowIndex - (cubeSpacing * 1.5 + cubeLength * 2 - cubeLength / 2) + rowIndex * cubeSpacing,
                  cellIndex - (cubeSpacing * 1.5 + cubeLength * 2 - cubeLength / 2) + cellIndex * cubeSpacing,
                ]}
                cell={cell}
                sliceIndex={sliceIndex}
                rowIndex={rowIndex}
                cellIndex={cellIndex}
                updateCell={props.updateCell}
                turnNumber={props.turnNumber}
                lastMove={props.lastMove}
                turn={props.turn}
                player={props.player}
                winningMatch={props.winningMatch}
                passiveHover={props.passiveHover}
                setPassiveHover={props.setPassiveHover}
                cubeHighlight={props.cubeHighlight}
                setCubeHighlight={props.setCubeHighlight}
                endScreen={props.endScreen}
              />
            );
          });
        });
      })}
      <CustomOrbitControls
        cameraControlRef={props.cameraControlRef}
        autoRotate={props.autoRotate}
        enablePan={props.enablePan}
        enableRotate={props.enableRotate}
        enableZoom={props.enableZoom}
      />
    </Canvas>
  );
};

export default ThreeDimView;
