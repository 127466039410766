import React, { useState, useEffect, useContext, useRef } from "react";
import "./../index.css";
import "../components/Background/Background.css";
import TwoDimView from "../components/TwoDimView";
import Header from "../components/Header";
import ResetGameModal from "../components/Modals/Modal_ResetGame";
import ConnectingModal from "../components/Modals/Modal_Connecting";
import { useLocation } from "react-router-dom";
import { SocketContext } from "../context/socket";
import ThreeDimView from "../components/ThreeDimView";
import CubeSpacingSlider from "../components/Sliders/CubeSpacingSlider";
import RedButton from "../components/Buttons/TEMPLATES/RedButton";
import { OrbitControls } from "@react-three/drei";
import RematchButton from "../components/Buttons/RematchButton";
import Background from "../components/Background/Background";

function Game() {
  const socket = useContext(SocketContext);
  const threeDimDirections = [
    [1, 1, 1],
    [1, 1, 0],
    [1, 1, -1],
    [1, 0, 1],
    [1, 0, 0],
    [1, 0, -1],
    [1, -1, 1],
    [1, -1, 0],
    [1, -1, -1],
    [0, 1, 1],
    [0, 1, 0],
    [0, 1, -1],
    [0, 0, 1],
  ];
  const initialTime = 300; // 5 minutes
  const cameraControlRef = useRef(<OrbitControls />);
  // Create the 4x4x4 array with zeros
  const [threeDimArray, setThreeDimArray] = useState(() =>
    new Array(4).fill(null).map(() => new Array(4).fill(null).map(() => new Array(4).fill(0))),
  );
  const [turn, setTurn] = useState(1); //turn is either 1 for host's turn, or -1 for guest's turn
  const [player, setPlayer] = useState(-1); //host is 1, guest is -1
  const [score, setScore] = useState([0, 0]);
  const [endScreen, setEndScreen] = useState(false);
  const [viewEnd, setViewEnd] = useState(false);
  const [hostConnected, setHostConnected] = useState(false);
  const [guestConnected, setGuestConnected] = useState(false);
  const [rematchStatus, setRematchStatus] = useState([0, 0]); //[1,0] means host requested rematch, [0,1] means guest requested rematch
  const [timerStatus, setTimerStatus] = useState([initialTime, initialTime]);
  const [RTtimerStatus, setRTtimerStatus] = useState([initialTime, initialTime]);
  const [timeOfLastUpdate, setTimeOfLastUpdate] = useState(new Date("not initialized"));
  const [turnNumber, setTurnNumber] = useState(0); //between turns, turns are set by the client. once the game ends, the end screen turn is set by the client aswell, the turns are synced upon rematch accept
  const [lastMove, setLastMove] = useState([]);
  const [winningMatch, setWinningMatch] = useState(); // [0,0] means no rematch, [1,0] means host won, [0,1] means guest won
  const [cubeSpacing, setCubeSpacing] = useState(0.5);
  const [passiveHover, setPassiveHover] = useState([null, null, null]); //[slice, row, cell]
  const [cubeHighlight, setCubeHighlight] = useState([null, null, null]); //[slice, row, cell]
  const [victor, setVictor] = useState(null);
  const [victoryType, setVictoryType] = useState("");
  const { state } = useLocation();
  const path = useLocation().pathname;
  const matchID = path.substring(1, path.length);
  useEffect(() => {
    // both host and guest run this
    socket.on("update_cell_stc", (slice, row, cell, senderPlayer, updateTime, timers) => {
      // console.log(`timers: ${timers} updateTime: ${updateTime}`);
      syncTimers(timers, updateTime);
      if (senderPlayer === player) {
        //update was sent by the client
        return;
      }
      updateCell(0, slice, row, cell, true);
    });

    return () => {
      socket.removeListener("update_cell_stc");
    };
  }, [turn, player, threeDimArray]);
  useEffect(() => {
    socket.on("opponent_left", () => {
      //requires dependency on player
      if (player === 1) {
        setGuestConnected(false);
      } else {
        setHostConnected(false);
      }
    });
    return () => {
      socket.removeListener("opponent_left");
    };
  }, [player]);
  useEffect(() => {
    socket.on("rematch_accepted_stc", (updateTime, timers, newTurn) => {
      syncTimers(timers, updateTime);
      resetGame();
      setTurn(newTurn);
    });
    return () => {
      socket.removeListener("rematch_accepted_stc");
    };
  }, [turn]);
  //one time setups
  // in the case of PRIVATE MATCHES
  // host has state = {host: true}
  // guest has state = null // hence the try catch

  // in the case of PUBLIC MATCHES
  // host has state = {host: true}
  // guest has state = {host: false} // hence the else block
  useEffect(() => {
    console.log(state);
    try {
      if (state.host === true) {
        setHostConnected(true);
        setPlayer(1);
        emitJoinMatch(matchID);
        console.log("I am the host");
      } else {
        emitJoinMatch(matchID);
      }
      console.log(`state : ${state}`);
    } catch {
      socket.on("connect", () => {
        console.log("I am the guest");
        //direct connection
        emitJoinMatch(matchID);
      });
    }
    socket.on("guest_connected", (turnStartTime, timers) => {
      syncTimers(timers, turnStartTime);
      setGuestConnected(true);
      console.log(`turnStartTime: ${turnStartTime}`);
      console.log(`timers: ${timers}`);
    });
    socket.on("host_connected", (turnStartTime, timers) => {
      syncTimers(timers, turnStartTime);
      setHostConnected(true);
      console.log(`turnStartTime: ${turnStartTime}`);
      console.log(`timers: ${timers}`);
    });
    socket.on("request_rematch_stc", (rematchStatus) => {
      setRematchStatus(rematchStatus);
    });

    socket.on("sync_timers_stc", (turnStartTime, timers) => {
      syncTimers(timers, turnStartTime);
    });

    socket.on("game_over_stc", (newScore, winnerPlayer, timers, victoryType) => {
      // get declaration from the server that the game is over either by timer or by match 4
      // syncTimers(timers, new Date());
      setEndScreen(true);
      setVictoryType(victoryType);
      setVictor(winnerPlayer);
      setScore(newScore);
    });
  }, []);

  function emitCell(slice, row, cell) {
    syncTimers(RTtimerStatus, new Date());
    socket.emit("update_cell_cts", matchID, player, slice, row, cell); //update cell client to server
  }
  function emitRematchRequest() {
    socket.emit("request_rematch_cts", matchID, player);
  }
  function emitJoinMatch(matchID) {
    socket.emit("join_match", matchID, (gameState, turn, turnNumber, player, gameOver, turnStartTime, timer, rematchStatus) => {
      syncTimers(timer, turnStartTime);
      setHostConnected(true);
      setGuestConnected(true);
      setTurn(turn === true || turn === 1 ? 1 : -1);
      setTurnNumber(turnNumber);
      setThreeDimArray(gameState);
      setPlayer(player);
      setEndScreen(gameOver);
      setRematchStatus(rematchStatus);
      console.log(`turnStartTime: ${turnStartTime}`);
      console.log(`timer: ${timer}`);
    });
  }
  function emitGameOver(winnerPlayer, victoryType = "match4") {
    socket.emit("game_over_cts", matchID, player, winnerPlayer, victoryType);
  }

  function DupFreePush(listOfArrays, newArray) {
    const isDuplicate = listOfArrays.some((arr) => JSON.stringify(arr) === JSON.stringify(newArray));
    if (!isDuplicate) {
      listOfArrays.push(newArray);
    }
  }

  function checkDirection(newArray, dirs, slice, row, cell, matches = []) {
    try {
      if (newArray[slice + dirs[0]][row + dirs[1]][cell + dirs[2]] === newArray[slice][row][cell]) {
        DupFreePush(matches, [slice, row, cell]);
        DupFreePush(matches, [slice + dirs[0], row + dirs[1], cell + dirs[2]]);
      }
      throw new Error("Check the other direction");
    } catch {
      try {
        if (newArray[slice - dirs[0]][row - dirs[1]][cell - dirs[2]] === newArray[slice][row][cell]) {
          DupFreePush(matches, [slice, row, cell]);
          DupFreePush(matches, [slice - dirs[0], row - dirs[1], cell - dirs[2]]);
        }
      } catch {}
    }
    return matches;
  }

  function updateCell(value, slice, row, cell, serverAuthority = false) {
    let stealFirstMove = false;
    if (endScreen) {
      return;
    }
    if (turnNumber === 1 && value === -player) {
      stealFirstMove = true;
    } else if (value !== 0) {
      console.log("Not a valid cell");
      return;
    }
    if (turn !== player && !serverAuthority) {
      //update wasnt by server
      console.log("Not your turn");
      return;
    }
    const newArray = [...threeDimArray]; // Create a shallow copy
    newArray[slice][row][cell] = turn;
    setLastMove([slice, row, cell]);
    if (!serverAuthority) {
      emitCell(slice, row, cell);
    }
    setThreeDimArray(newArray); // Update the state with the new array
    let gameOver = false;
    for (let i = 0; i < threeDimDirections.length; i++) {
      const dirs = threeDimDirections[i];
      let matches = checkDirection(newArray, dirs, slice, row, cell);
      for (let i = 1; i < matches.length; i++) {
        matches = checkDirection(newArray, dirs, matches[i][0], matches[i][1], matches[i][2], (matches = matches));
      }
      if (matches.length === 4) {
        setWinningMatch(matches);
        setWinner(turn);
        gameOver = true;
      }
    }
    if (!gameOver) {
      setTurnNumber(turnNumber + 1);
      setTurn(-turn);
    }
  }
  function decrementTime() {
    let runningTime;
    //when deployed remotely, the runningTime subtraction factor is off by about 0.5 seconds. I suspect its due to server latency
    //because the subtraction factor becomes about -0.5, the timer jumps about 0.5 every turn swap
    //this means timeOfLastUpdate sent by the server is actually about 0.5 seconds before the client's time
    let timeDifference = ((new Date() - timeOfLastUpdate) / 1000).toFixed(2);
    if (timeDifference < 0) {
      console.log(`server latency: ${timeDifference} s at turn ${turnNumber}`);
      setTimeOfLastUpdate(new Date());
      timeDifference = 0;
    }
    runningTime = timerStatus[turn === 1 ? 0 : 1] - timeDifference;
    // console.log(`runningTime Subtraction: ${((new Date() - timeOfLastUpdate) / 1000).toFixed(2)}`);
    if (turn === 1) {
      setRTtimerStatus([runningTime, timerStatus[1]]);
      // console.log(`RTtimerStatus:${[runningTime, timerStatus[1]]} turnNumber:${turnNumber}`);
    } else {
      setRTtimerStatus([timerStatus[0], runningTime]);
      // console.log(`RTtimerStatus:${[timerStatus[0], runningTime]} turnNumber:${turnNumber}`);
      // console.log(`Current Guest Difference : ${((new Date() - timeOfLastUpdate) / 1000).toFixed(2)}`);
    }

    if (runningTime <= 0 && !endScreen) {
      if (turn === 1) {
        //host ran out of time
        setTimerStatus([0, timerStatus[1]]);
      } else {
        setTimerStatus([timerStatus[0], 0]);
      }
      setWinner(-turn, "timeout"); //the player who runs out of time gives the point to the opponent
    }
  }
  useEffect(() => {
    let timer;

    if (!endScreen && turnNumber > 0 && guestConnected && hostConnected) {
      timer = setInterval(decrementTime, 10); // Decrease time every 10milliseconds (0.01 second)
    }
    // setRTtimerStatus(timerStatus); // makes the timers seem smoother?
    // Cleanup function to clear the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, [timerStatus, timeOfLastUpdate, turn, endScreen, guestConnected, hostConnected]);
  function syncTimers(timers, updateTimeString) {
    setTimeOfLastUpdate(new Date(updateTimeString));
    setTimerStatus(timers);
    setRTtimerStatus(timers);
  }
  function resetGame() {
    setThreeDimArray(() => new Array(4).fill(null).map(() => new Array(4).fill(null).map(() => new Array(4).fill(0))));
    setTurnNumber(0);
    setLastMove([null, null, null]);
    setWinningMatch([]);
    setEndScreen(false);
    setRematchStatus([0, 0]);
    setViewEnd(false);
  }
  function setWinner(winningPlayer, victoryType = "match4") {
    setEndScreen(true);
    emitGameOver(winningPlayer, victoryType);
  }
  //header is 1/12 height
  //rest of body is 11/12 height

  //side cards are 1/12 width
  //3d view is 8/12 width
  ///2d view is 2/12 width
  return (
    <>
      <Background turn={turn} />
      <div className="h-screen w-screen flex flex-col block">
        <Header turn={turn} score={score} player={player} RTtimerStatus={RTtimerStatus} />
        <div className="flex flex-row h-full w-full">
          {/* <div className="grid grid-cols-3"> */}
          {(!hostConnected || !guestConnected) && <ConnectingModal matchID={matchID} />}
          {endScreen && hostConnected && guestConnected && !viewEnd && (
            <ResetGameModal victor={victor} requestRematch={emitRematchRequest} rematchStatus={rematchStatus} setViewEnd={setViewEnd} />
          )}
          <div className="w-2/12">
            <div className="flex flex-col items-center justify-center">
              {/* <SideCard side={"left"} player={player} timer={RTtimerStatus[0]} />
              <SideCard side={"right"} player={player} timer={RTtimerStatus[1]} /> */}
              <CubeSpacingSlider setCubeSpacing={setCubeSpacing} />
              <RedButton
                text="Reset View"
                onMouseUp={() => {
                  cameraControlRef.current?.reset(true);
                }}
                onClick={() => {}}
              ></RedButton>

              {endScreen && viewEnd && (
                <RematchButton text="" onMouseUp={() => emitRematchRequest()} onClick={() => {}} rematchStatus={rematchStatus} />
              )}
            </div>
          </div>
          <div className="h-full my-auto grow w-8/12">
            <ThreeDimView
              //gamestate props
              threeDimArray={threeDimArray}
              updateCell={updateCell}
              turnNumber={turnNumber}
              turn={turn}
              player={player}
              lastMove={lastMove}
              winningMatch={winningMatch}
              passiveHover={passiveHover}
              setPassiveHover={setPassiveHover}
              cubeHighlight={cubeHighlight}
              setCubeHighlight={setCubeHighlight}
              endScreen={endScreen}
              //camera props
              cubeSpacing={cubeSpacing}
              enablePan={false}
              cameraControlRef={cameraControlRef}
            />
          </div>
          <div className="w-2/12 my-auto">
            <div className="h-1/2 flex flex-col items-center justify-center">
              <TwoDimView
                threeDimArray={threeDimArray}
                updateCell={updateCell}
                turnNumber={turnNumber}
                turn={turn}
                player={player}
                lastMove={lastMove}
                winningMatch={winningMatch}
                passiveHover={passiveHover}
                setPassiveHover={setPassiveHover}
                cubeHighlight={cubeHighlight}
                setCubeHighlight={setCubeHighlight}
                endScreen={endScreen}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Game;
