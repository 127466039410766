import React from "react";
import SideCard from "./SideCard";
const Header = (props) => {
  const backgroundColor = props.turn === 1 ? "bg-blue-300" : "bg-red-300";
  return (
    <div className={`grid grid-cols-3 text-center ${backgroundColor} mb-2 h-1/12`}>
      <div className="m-auto">
        {/* <img className="w-8 h-8" src={logo} onClick={() => {}}></img> */}
        <SideCard side={"left"} player={props.player} timer={props.RTtimerStatus[0]} />
      </div>
      <div>
        <h2 className="text-5xl font-bold p-4">
          <span className="text-blue-500">{props.score[0]}</span>-<span className="text-red-500">{props.score[1]}</span>
        </h2>
      </div>
      <div className="m-auto">
        <SideCard side={"right"} player={props.player} timer={props.RTtimerStatus[1]} />
      </div>
      {/* <h2 className="text-5xl font-bold p-4">{title}</h2> */}
    </div>
  );
};

export default Header;
