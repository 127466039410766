import React from "react";
import { useRef, useState } from "react";
import { Outlines, useCursor } from "@react-three/drei";
import { Color } from "three";
import colours from "../constants/colours";

const Cube = (props) => {
  const showOrientation = true;
  const meshRef = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHovered] = useState(false);

  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => (meshRef.current.rotation.x += delta));
  // Return view, these are regular three.js elements expressed in JSX
  // Sets document.body.style.cursor: useCursor(flag, onPointerOver = 'pointer', onPointerOut = 'auto')

  let winningCell = false;
  let cubeColour = new Color(colours.blue[1]);
  let borderColour = "white";
  if (props.winningMatch) {
    for (let i = 0; i < props.winningMatch.length; i++) {
      if (
        props.winningMatch[i][0] === props.sliceIndex &&
        props.winningMatch[i][1] === props.rowIndex &&
        props.winningMatch[i][2] === props.cellIndex
      ) {
        winningCell = true;
      }
    }
  }
  let lastMove = false;
  if (!props.lastMove === undefined) {
    let lastMove =
      `${props.sliceIndex}-${props.rowIndex}-${props.cellIndex}` === `${props.lastMove[0]}-${props.lastMove[1]}-${props.lastMove[2]}`;
  }
  //
  const firstMove = props.turnNumber === 1 && props.cell;
  if (props.cell === 0 && props.turn === props.player && !props.endScreen) {
    cubeColour = "white";
  } else if (props.cell === 1) {
    cubeColour = colours.blue[1];
  } else if (props.cell === -1) {
    cubeColour = colours.red[1];
  } else if ((props.cell === 0 && props.turn !== props.player) || props.endScreen) {
    cubeColour = colours.black[1];
  }
  // if (showOrientation) {
  //   if (props.sliceIndex === 0) {
  //     borderColour = colours.red[1];
  //   } else if (props.sliceIndex === 1) {
  //     borderColour = colours.blue[1];
  //   } else if (props.sliceIndex === 2) {
  //     borderColour = colours.green[1];
  //   } else if (props.sliceIndex === 3) {
  //     borderColour = colours.yellow[1];
  //   }
  // }
  // if (firstMove && props.turn === props.player) {
  //   cellColour = cellColour.concat(" cursor-pointer");
  // }

  //if the cell is available and being hovered, set the cursor to pointer
  let available = (hovered && props.cell === 0 && props.turn === props.player) || (hovered && firstMove && props.turn === props.player);
  if (props.endScreen) {
    available = false;
  }

  //if hover is detected, set the passive hover to this coordinate
  if (hovered && props.setPassiveHover) {
    props.setPassiveHover([props.sliceIndex, props.rowIndex, props.cellIndex]);
  }

  //if cell in 2dview is being hovered, set outline to this one
  let twoDHover = false;
  if (!props.passiveHover) {
  } else if (
    props.passiveHover[0] === props.sliceIndex &&
    props.passiveHover[1] === props.rowIndex &&
    props.passiveHover[2] === props.cellIndex
  ) {
    twoDHover = true;
  }
  let cubeHighlight = false;
  if (!props.cubeHighlight) {
  } else if (
    props.cubeHighlight[0] === props.sliceIndex &&
    props.cubeHighlight[1] === props.rowIndex &&
    props.cubeHighlight[2] === props.cellIndex
  ) {
    cubeHighlight = true;
  }

  if (cubeHighlight) {
    borderColour = "yellow";
  }

  useCursor(available);
  return (
    <mesh
      {...props}
      ref={meshRef}
      // scale={active ? 1.5 : 1}
      onClick={(e) => (e.stopPropagation(), props.updateCell(props.cell, props.sliceIndex, props.rowIndex, props.cellIndex))}
      onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
      onPointerOut={(e) => setHovered(false)}
      onContextMenu={(e) => (
        e.stopPropagation(), props.setCubeHighlight([props.sliceIndex, props.rowIndex, props.cellIndex])
        // () => {
        //   if (
        //     props.cubeHighlight[0] === props.sliceIndex &&
        //     props.cubeHighlight[1] === props.rowIndex &&
        //     props.cubeHighlight[2] === props.cellIndex
        //   ) {
        //     props.setCubeHighlight([null, null, null]);
        //   } else {
        //     props.setCubeHighlight([props.sliceIndex, props.rowIndex, props.cellIndex]);
        //   }
        // }
      )}
    >
      {/* <RoundedBox args={[1, 1, 1]} /> */}
      <boxGeometry args={[1, 1, 1]} />

      <meshStandardMaterial roughness={1} transparent opacity={0.6} color={cubeColour} />
      <Outlines thickness={available || twoDHover ? 0.1 : 0.05} color={borderColour} />
    </mesh>
  );
};

export default Cube;
