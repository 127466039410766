import React from "react";

import Cell from "./Cell";
const TwoDimView = (props) => {
  // console.log("TWO DIM UPDATE");
  const backgroundColors = [
    "bg-gradient-to-r from-red-500 to-red-900",
    "bg-gradient-to-r from-green-500 to-green-900",
    "bg-gradient-to-r from-blue-500 to-blue-900",
    "bg-gradient-to-r from-yellow-500 to-yellow-900",
  ];
  return (
    <div className="flex flex-col space-y-3">
      {props.threeDimArray.map((slice, sliceIndex) => (
        <div
          key={sliceIndex}
          className={`flex flex-col space-y-2 p-2 rounded-lg ${backgroundColors[sliceIndex % backgroundColors.length]}`}
          onContextMenu={(e) => {
            e.preventDefault(); // Prevent the default context menu
            // console.log("right click");
          }}
        >
          {/* ea is a flexbox of 4x4 */}
          {slice.map((row, rowIndex) => (
            <div key={rowIndex} className="flex space-x-2">
              {row.map((cell, cellIndex) => (
                <div key={cellIndex}>
                  <Cell
                    cell={cell}
                    sliceIndex={sliceIndex}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                    updateCell={props.updateCell}
                    turnNumber={props.turnNumber}
                    lastMove={props.lastMove}
                    turn={props.turn}
                    player={props.player}
                    winningMatch={props.winningMatch}
                    passiveHover={props.passiveHover}
                    setPassiveHover={props.setPassiveHover}
                    cubeHighlight={props.cubeHighlight}
                    setCubeHighlight={props.setCubeHighlight}
                    endScreen={props.endScreen}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TwoDimView;
