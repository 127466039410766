import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import Game from "./pages/Game";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SocketContext, socket } from "./context/socket";

//REACT
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SocketContext.Provider value={socket}>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Game />}></Route>
      </Routes>
    </Router>
  </SocketContext.Provider>,
);
