import { io } from "socket.io-client";
import React from "react";

const isLocalHost = window.location.hostname === "localhost";

/* config with nginx*/
// const host = "http://24.156.164.4:8080";

/* config with render.com */
const host = isLocalHost ? "http://localhost:8080" : `https://four-cubed.onrender.com`;

export const socket = io(host);
socket.on("connect", () => {
  console.log(`Connected with ID ${socket.id}`);
});
export const SocketContext = React.createContext();
