import React from "react";
import "./Slider.css";
import ReactSlider from "react-slider";

const CubeSpacingSlider = (props) => {
  return (
    <ReactSlider
      defaultValue={50}
      className="horizontal-slider"
      thumbClassName="example-thumb"
      trackClassName="example-track"
      renderThumb={(props, state) => <div {...props}>{``}</div>}
      onChange={(newValue, thumbIndex) => {
        props.setCubeSpacing(newValue / 100);
      }}
    />
  );
};

export default CubeSpacingSlider;
