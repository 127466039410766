import React from "react";
import { MOUSE } from "three";

import { OrbitControls } from "@react-three/drei";
const CustomOrbitControls = (props) => {
  // useFrame((state) => {
  //   state.camera.position.lerp({ x: 50, y: 25, z: 50 }, 0.03);
  //   state.camera.lookAt(0, 0, 0);
  // });
  return (
    <OrbitControls
      ref={props.cameraControlRef}
      autoRotate={props.autoRotate}
      enablePan={props.enablePan}
      enableRotate={props.enableRotate}
      enableZoom={props.enableZoom}
      mouseButtons={{
        RIGHT: MOUSE.ROTATE,
      }}
      target={[0, 0, 0]}
      enableKeys={false}
    />
  );
};

export default CustomOrbitControls;
