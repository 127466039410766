const colours = {
  //300,500,700
  blue: ["#93c5fd", "#3b82f6", "#1d4ed8"],
  red: ["#fca5a5", "#ef4444", "#b91c1c"],
  neutral: ["#d4d4d4", "#737373", "#404040"],
  black: ["#000000", "#000000", "#000000"],
  green: ["#86efac", "#22c55e", "#15803d"],
  yellow: ["#fde047", "#eab308", "#a16207"],
};

export default colours;
