import React from "react";

const BlueButton = (props) => {
  //const boxShadowColour = ["#1b6ff8", "#1b70f841"];
  return (
    <div
      onClick={() => props.onClick()}
      onMouseUp={() => setTimeout(() => props.onMouseUp(), 100)}
      className="button w-40 h-16 bg-blue-500 rounded-lg cursor-pointer select-none
    active:translate-y-2  active:[box-shadow:0_0px_0_0_#1b6ff8,0_0px_0_0_#1b70f841]
    active:border-b-[0px]
    transition-all duration-150 [box-shadow:0_10px_0_0_#1b6ff8,0_15px_0_0_#1b70f841]
    border-b-[1px] border-blue-400"
    >
      <span className="flex flex-col justify-center items-center h-full text-white font-bold text-lg ">{props.text}</span>
    </div>
  );
};

export default BlueButton;
